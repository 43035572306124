/* src/Header.css */
.header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: #2C3E50;
    position: fixed;
    top: 0;
    left: 0;
    width: 98%;
    z-index: 1000;
}

.logo img {
    height: 50px;
}

.navbar ul {
    display: flex;
    list-style: none;
}

.navbar li {
    margin: 0 10px;
}

.navbar a {
    color: white;
    text-decoration: none;
}

.navbar a:hover {
    text-decoration: underline;
}

/* Styling for the AI Assistant Button */
.ai-assistant-btn {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 12px 25px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.8s;
}

.ai-assistant-btn:hover {
    background-color: #45a049;
}

/* Optional: Add icon to the button (if you're using an icon instead of text) */
.ai-assistant-btn img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

/* Styling for the AI Assistant Icon */
.ai-link {
    display: inline-block;
    text-decoration: none;
    /* Remove underline */
}

.ai-icon {
    width: 30px;
    /* Adjust the icon size */
    height: 30px;
    filter: invert(100%);
    /* Makes the icon lighter for dark backgrounds */
    transition: transform 0.3s ease;
    margin-bottom: 20px;
}

.ai-icon:hover {
    transform: scale(1.1);
    /* Slight zoom effect on hover */
}

/* Styling for the Navbar and Button */
.navbar ul {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    padding: 0;
}

.navbar li {
    margin-left: 20px;
    /* Spacing between menu items */
}

/* Style for the "AI Assistant" Button Text */
/* .navbar li a {
    font-size: 16px;
    color: white;
    
    background-color: #4CAF50;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    display: flex;
    align-items: center;
} */

/* .navbar li a:hover {
    background-color: #45a049;
}

*/