/* Features Page Styling */
.features {
    padding: 50px 20px;
    background-color: #f9f9f9;
    text-align: center;
}

/* Container for Feature List and Image */
.features-container {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    flex-wrap: wrap;
    /* Ensures responsiveness */
    margin-top: 30px;
}

/* Left side for Feature List */
.feature-list {
    width: 60%;
    /* Feature list takes up 60% of the container */
}

/* Styling for each individual feature */
.feature {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    transition: transform 0.3s ease;
}

.feature:hover {
    transform: translateY(-10px);
}

.feature h3 {
    font-size: 1.75rem;
    margin-bottom: 10px;
    color: #333;
}

.feature p {
    font-size: 1rem;
    color: #666;
}

/* Right side for the Image */
.image-container {
    width: 35%;
    /* Image container takes up 35% of the container */
}

.features-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Mobile Responsive Design */
@media (max-width: 768px) {
    .features-container {
        flex-direction: column;
        align-items: center;
    }

    .feature-list,
    .image-container {
        width: 100%;
        /* Both feature list and image container take full width on mobile */
    }

    .image-container {
        margin-top: 30px;
        /* Add some space between feature list and image on mobile */
    }

    .features-image {
        width: 80%;
        /* Make the image slightly smaller for mobile */
    }
}