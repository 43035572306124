/* src/components/Contact/Contact.css */
.contact {
    padding: 50px;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.contact h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
}

.contact p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: #555;
}

.contact form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact input,
.contact textarea {
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 1rem;
    box-sizing: border-box;
}

.contact button {
    padding: 15px 30px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.contact button:hover {
    background-color: #45a049;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .contact {
        padding: 20px;
    }

    .contact h1 {
        font-size: 2rem;
    }

    .contact p {
        font-size: 1rem;
    }

    .contact input,
    .contact textarea {
        width: 90%;
    }

    .contact button {
        width: 90%;
    }
}

.email-contact {
    margin-top: 20px;
    text-align: center;
    font-size: 16px;
    color: #333;
}

.email-link {
    font-weight: bold;
    color: #007bff;
    text-decoration: none;
    border: 1px solid #007bff;
    padding: 8px 15px;
    border-radius: 5px;
    margin-top: 10px;
    display: inline-block;
}

.email-link:hover {
    background-color: #f1f1f1;
    color: #0056b3;
}