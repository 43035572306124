/* Main container for the AI Assistant page */
.ai-assistant {
    padding: 50px 20px;
    background-color: #f9f9f9;
    text-align: center;
    font-family: Arial, sans-serif;
}

/* Heading for the AI Assistant page */
.ai-assistant h1 {
    font-size: 3rem;
    margin-bottom: 30px;
    font-weight: bold;
    color: #333;
}

/* Section for email input */
.email-entry {
    margin-bottom: 30px;
}

.email-entry h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
}

/* Email input form */
.email-entry form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.email-entry input {
    padding: 12px;
    font-size: 16px;
    width: 70%;
    max-width: 400px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 15px;
}

.email-entry button {
    padding: 12px 30px;
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.email-entry button:hover {
    background-color: #45a049;
}

/* Section for the query input form */
.query-section {
    margin-top: 50px;
}

.query-section h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
}

/* Form to submit the AI query */
.query-section form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.query-section input {
    padding: 12px;
    font-size: 16px;
    width: 70%;
    max-width: 400px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 15px;
}

.query-section button {
    padding: 12px 30px;
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.query-section button:hover {
    background-color: #45a049;
}

/* Section for displaying the response from AI */
.response {
    margin-top: 30px;
    padding: 20px;
    background-color: #e9f5e9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 20px auto;
    text-align: left;
}

.response p {
    font-size: 1.2rem;
    color: #333;
}

/* Mobile Responsive Design */
@media (max-width: 768px) {
    .ai-assistant {
        padding: 20px;
    }

    .email-entry input,
    .query-section input {
        width: 80%;
        /* Increase input width on mobile */
    }

    .email-entry button,
    .query-section button {
        width: 80%;
        /* Increase button width on mobile */
    }

    .response {
        width: 90%;
        /* Increase response container width on mobile */
    }
}

.chat {
    max-height: 400px;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #f9f9f9;
    margin-top: 20px;
}

.chat-history {
    display: flex;
    flex-direction: column;
}

.message {
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
}

.message.user {
    background-color: #d3f8d3;
    text-align: right;
}

.message.assistant {
    background-color: #f1f1f1;
}

strong {
    font-weight: bold;
}