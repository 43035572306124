/* Signup Page Styling */
.signup-container {
    display: flex;
    height: 100vh;
    width: 100%;
}

/* Left Section with Image */
.left-section {
    flex: 1;
    background-color: #f0f0f0;
}

.signup-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Right Section for the Form */
.right-section {
    flex: 1;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

/* Form Container */
.form-container {
    width: 100%;
    max-width: 400px;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Heading */
.form-container h2 {
    text-align: center;
    margin-bottom: 20px;
}

/* Form Elements */
.form-group {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
}

input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

input:focus {
    border-color: #4CAF50;
    outline: none;
}

/* Button Styling */
.cta-button-signup {
    width: 100%;
    padding: 12px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.cta-button-signup:hover {
    background-color: #45a049;
}