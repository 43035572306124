/* Hero Section Styling */
.hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
    background-image: url('/assets/hero-image.jpg');
    background-size: cover;
    background-position: center;
    text-align: left;
    color: black;
    position: relative;
    padding: 0 20px;
}

.hero-content {
    max-width: 100%;
    /* Ensure it doesn't stretch too much */
    margin: 0;
    text-align: left;
    padding: 20px;
    /* Remove background color if no overlay needed */
    border-radius: 10px;
    width: 50%;
    /* Content takes 50% of the screen width for larger screens */
}

.hero h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 15px;
}

.hero p {
    font-size: 1.5rem;
    margin-bottom: 30px;
    opacity: 0.8;
}

/* Button Container Styling */
/* Button Container Styling */
.button-container {
    display: flex;
    justify-content: space-between;
    /* Ensures the buttons are spaced correctly */
    align-items: center;
    /* Vertically align the buttons */
    gap: 20px;
    /* Adjust the gap between the buttons */
    margin-top: 20px;
    /* Adjust the space above the buttons */
    width: 100%;
    /* Ensure the container takes full width */
    flex-wrap: nowrap;
    /* Prevent buttons from wrapping */
}

/* Styling for both the "Get Started" Button (Primary) and the "Request a Demo" Button (Secondary) */
button {
    font-size: 16px;
    padding: 12px 35px;
    /* Consistent padding */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s;
    height: 50px;
    /* Ensure buttons have the same height */
    width: 48%;
    /* Equal width for both buttons */
}

/* Styling for the "Get Started" Button (Primary) */
.cta-button {
    background-color: #4CAF50;
    color: white;
    margin-bottom: 15px;
}

.cta-button:hover {
    background-color: #45a049;
}

/* Styling for the "Request a Demo" Button (Secondary) */
.secondary-button {
    background-color: #FF5733;
    color: white;
}

.secondary-button:hover {
    background-color: #E64A19;
}

/* Mobile Responsive: Stack buttons on smaller screens */
@media (max-width: 768px) {
    .button-container {
        flex-direction: column;
        /* Stack buttons vertically */
        gap: 15px;
        /* Reduce the gap between stacked buttons */
        align-items: center;
        /* Center the buttons */
    }

    button {
        width: 80%;
        /* Make the buttons slightly smaller to fit nicely on mobile */
    }
}

/* Mobile Responsive Design for Hero Section */
@media (max-width: 768px) {
    .hero-content {
        width: 100%;
        padding: 15px;
        text-align: center;
    }

    .hero h1 {
        font-size: 2.5rem;
    }

    .hero p {
        font-size: 1.2rem;
    }

    .cta-button {
        width: 100%;
        /* Ensure the button spans the full width on mobile */
        margin: 20px 0;
    }

    .secondary-button {
        width: 100%;
        /* Ensure the button spans the full width on mobile */
        margin: 20px 0;
    }
}


/* Key Features Section */
.features {
    text-align: center;
    padding: 50px 20px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 40px;
}

.features h2 {
    font-size: 2.5rem;
    margin-bottom: 30px;
    font-weight: bold;
}

/* Feature Section with Icons */
.feature-section {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
}

.feature-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 30%;
    /* Ensure uniform size for each feature */
    transition: transform 0.3s ease;
}

.feature-item:hover {
    transform: translateY(-10px);
}

.feature-icon {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
    transition: transform 0.3s ease;
}

.feature-text {
    text-align: center;
}

.feature-text h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
}

.feature-text p {
    font-size: 1rem;
    color: #666;
}

/* Mobile Responsive Design for Key Features */
@media (max-width: 768px) {
    .feature-section {
        flex-direction: column;
        gap: 20px;
    }

    .feature-item {
        width: 80%;
        /* Adjust width for mobile */
    }

    .feature-icon {
        width: 80px;
        height: 80px;
    }
}


/* Styling for the "Request a Demo" Button */

/* Modal styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: white;
    padding: 35px;
    border-radius: 10px;
    width: 400px;
    max-width: 90%;
    position: relative;
}

.form-field {
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 8px;
}

.submit-button {
    width: 100%;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.submit-button:hover {
    background-color: #45a049;
}

.close {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
    color: #333;
    cursor: pointer;
    transition: color 0.3s;
}

.close:hover {
    color: #FF5733;
    /* Match the button hover color */
}

form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

input,
select {
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

button.submit-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 12px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

button.submit-button:hover {
    background-color: #45a049;
}

/** How it works */
/* How it Works Section */
#how-it-works {
    text-align: center;
    padding: 50px 20px;
    background-color: #f9f9f9;
}

#how-it-works h2 {
    font-size: 2.5rem;
    margin-bottom: 30px;
    font-weight: bold;
}

.cards-container {
    display: flex;
    justify-content: space-between;
    /* Adjust spacing between cards */
    gap: 15px;
    /* Reduced gap for compactness */
    flex-wrap: wrap;
    /* Allow cards to wrap if needed */
    margin-top: 20px;
    width: 100%;
}

.card {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 28%;
    /* Adjust width of each card for desktop */
    text-align: center;
    transition: transform 0.3s ease;
    margin-bottom: 20px;
    /* Ensures spacing if wrapping occurs */
}

/* Hover effect */
.card:hover {
    transform: scale(1.05);
}

.card h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
}

.card p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 20px;
}

/* Learn More Button */
.learn-more-btn {
    font-size: 16px;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s;
}

.learn-more-btn:hover {
    background-color: #45a049;
}

/* Mobile Responsive Design */
@media (max-width: 768px) {
    .cards-container {
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }

    .card {
        width: 80%;
        /* Adjust width of each card for mobile */
    }
}

/* Partners Section */
#partners {
    padding: 50px 20px;
    background-color: #f9f9f9;
    text-align: center;
}

#partners h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    font-weight: bold;
}

#partners p {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 30px;
}

.partner-logos {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 40px;
    max-width: 1200px;
    margin: 0 auto;
}

.partner-logo {
    width: 150px;
    /* Adjust width of each logo */
    height: 100px;
    /* Adjust height */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.partner-logo img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

/* Mobile Responsive Design */
@media (max-width: 768px) {
    .partner-logos {
        justify-content: center;
        gap: 20px;
    }

    .partner-logo {
        width: 120px;
        /* Adjust width for mobile */
        height: 80px;
        /* Adjust height for mobile */
    }
}

.ai-assistant-btn {
    background-color: #FF5733;
    /* Orange color */
    color: white;
    font-size: 16px;
    padding: 12px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-left: 20px;
    /* Space between buttons */
    width: auto;
    /* Adjust width */
}

.ai-assistant-btn:hover {
    background-color: #E64A19;
    /* Darker shade of orange */
}

/* Demo Section */
.demo-section {
    text-align: center;
    padding: 50px 20px;
    background-color: #f9f9f9;
}

.demo-btn {
    background-color: #000000;
    color: white;
    font-size: 16px;
    padding: 15px 40px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.demo-btn:hover {
    background-color: #4CAF50;
}

/* Responsive Design */
@media (max-width: 768px) {
    .hero-content {
        padding: 20px;
    }

    .cta-button,
    .ai-assistant-btn {
        width: 100%;
        margin-bottom: 10px;
    }

    .button-container {
        flex-direction: column;
    }

    .demo-btn {
        width: 100%;
    }
}