/* Common Styles for Header, Bullet Points, and CTA Button */
.page-header {
    display: flex;
    align-items: center; /* Vertically center content */
    justify-content: space-between; /* Distribute content evenly */
    padding: 40px 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    margin-bottom: 30px;
}

/* Adjust the content section */
.page-header .content {
    flex: 1; /* Take up available space */
    text-align: left; /* Align text to the left */
    margin-right: 20px; /* Space between content and image */
}

/* Adjust the image */
.page-header .tracking-image {
    max-width: 45%; /* Set the image width to 45% */
    height: auto;
    border-radius: 10px;
}

/* Header styling */
.page-header h1 {
    font-size: 2rem;
    margin-bottom: 15px;
    font-weight: bold;
}

.page-header p {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 20px;
}

/* Bullet points section */
.page-header ul {
    list-style-type: disc;
    padding-left: 40px;
    text-align: left;
    color: #333;
    font-size: 1rem;
    line-height: 1.8;
    margin-bottom: 30px;
}

.page-header ul li {
    margin-bottom: 15px;
}

/* CTA Button Styling */
.cta-button {
    padding: 12px 35px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: inline-block;
    margin-top: 20px;
    text-align: center;
}

.cta-button:hover {
    background-color: #45a049;
}

/* Mobile Responsive */
@media (max-width: 768px) {
    .page-header {
        flex-direction: column; /* Stack content and image on top of each other on small screens */
        align-items: center;
        justify-content: center;
    }

    .page-header .tracking-image {
        max-width: 80%; /* Reduce image size on mobile */
        margin-top: 20px;
    }

    .page-header .content {
        text-align: center;
        margin-right: 0; /* Remove margin on mobile */
    }

    .page-header ul {
        padding-left: 20px;
    }
}
