/* src/Footer.css */
.footer {
    background-color: #2C3E50;
    color: white;
    padding: 10px;
    text-align: center;
    position: relative;
    /* Normal flow positioning */
    width: 100%;
    margin-top: auto;
    /* Ensure the footer is pushed to the bottom of the page */
    /* The auto margin ensures that the footer does not overlap */
    flex-shrink: 0;
    /* Ensures footer doesn't shrink in flex container */
}

.social-links {
    list-style: none;
    padding: 0;
}

.social-links li {
    display: inline-block;
    margin: 0 10px;
}

.social-links a {
    color: white;
    text-decoration: none;
}