/* src/AboutUs.css */
.about-us {
    padding: 50px 20px;
    text-align: center;
}

.about-us-header {
    margin-bottom: 40px;
}

.about-us h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.about-us p {
    font-size: 1.25rem;
    color: #555;
}

.founder-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 30px;
    gap: 30px;
    max-width: 1200px;
    margin: 0 auto;
}

.founder-image img {
    border-radius: 50%;
    height: 215px;
    /* Same as width for perfect circle */
    width: 200px;
    /* Same as height for perfect circle */
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.founder-bio {
    max-width: 600px;
    text-align: left;
    margin-top: 10px;
}

.founder-bio h2 {
    font-size: 2rem;
    margin-bottom: 10px;
}

.founder-bio h3 {
    font-size: 1.5rem;
    color: #2C3E50;
    margin-bottom: 20px;
}

.founder-bio p {
    font-size: 1rem;
    line-height: 1.5;
    color: #333;
    margin-bottom: 20px;
}

.cta {
    margin-top: 40px;
}

.cta-button {
    background-color: #4CAF50;
    color: white;
    padding: 15px 35px;
    text-decoration: none;
    font-size: 18px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.cta-button:hover {
    background-color: #45a049;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .founder-section {
        flex-direction: column;
        align-items: center;
    }

    .founder-bio {
        text-align: center;
    }

    .founder-image img {
        height: 150px;
        width: 150px;
    }
}