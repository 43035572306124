/* src/globalStyles.css */

/* Body and HTML setup for full height and margin reset */
body,
html {
    height: 100%;
    margin: 0;
}

/* Apply flexbox to handle full-page height with footer always at bottom */
.main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    /* Ensure full screen height */
}

/* Push footer to the bottom of the screen if content is not enough */
footer {
    margin-top: auto;
}

/* Optional - Adjust padding on smaller screens */
@media (max-width: 768px) {
    .main-container {
        padding-bottom: 100px;
        /* Space for footer on mobile */
    }
}